import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import { Col, Container, Form, Row } from "react-bootstrap"
import axios from "axios"
import transfezLogo from "../../images/transfez-new-logo-navbar-white.png"
import jackLogo from "../../images/jack-logo-white.svg"
import validator from "validator"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheck,
  faTimesCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons"
import {
  Content,
  Description,
  Subscribe,
  SubscribeButton,
  InputGroupStyled,
  FormControlStyled,
  SubscribeCol,
  SubscribeBreak,
  AlertDiv,
  LogoSocmed,
  ContainerWrapper,
  FooterOrnament,
  FooterContactUsTitle,
  EmailSpan,
  FooterJackBussiness,
} from "./FooterStyle"
import BottomFooter from "./BottomFooter"
import FooterLogo from "./FooterLogo"
import NavbarFooter from "./NavbarFooter"
import { IdComponent } from "../Reusable/id-components"
// import footerImg from "../../images/footer-ornament.png"
import rsr from "react-string-replace"
import { graphql, Link, useStaticQuery } from "gatsby"
import { useLocation } from "@reach/router"
import { LogoSocial } from "./LogoSocial"

function Footer({ props, propsLink }) {
  const [subscriber, setSubscriber] = useState("")
  const [emailValid, setEmailValid] = useState(false)
  const [done, setDone] = useState(false)
  const [already, setAlready] = useState(false)
  const isEnglish = useLocation().pathname.includes("/en")
  const contactUsLink = isEnglish ? "/en/contact-us/" : "/contact-us/"

  const data = useStaticQuery(graphql`
    {
      footer: file(relativePath: { eq: "footer-ornament.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const footerImg = data.footer.childImageSharp.fluid

  function onChange(e) {
    setAlready(false)
    setDone(false)
    const valid = validator.isEmail(e.target.value)
    if (valid) {
      setEmailValid(true)
    } else {
      setEmailValid(false)
    }
    setSubscriber(e.target.value)
  }

  function onSubmit(e) {
    const nampung = []
    e.preventDefault()
    const headers = {
      Authorization:
        "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo0fQ.HzngeSUFSrGEeCk4lL7zHElSUhljNf_0PSK1uB5UWkg",
    }
    axios
      .get(`https://api.webform.transfez.com/api/v1/subscribes/`, {
        headers: headers,
      })
      .then(res => {
        for (let i = 0; i < res.data.data.length; i++) {
          if (res.data.data[i].email === subscriber) {
            setAlready(true)
            setDone(false)
            nampung.push(res.data.data[i].email)
          }
        }
        if (nampung.length === 0) {
          axios
            .post(
              "https://api.webform.transfez.com/api/v1/subscribes/",
              { email: subscriber },
              { headers: headers }
            )
            .then(res => {
              setDone(true)
              setAlready(false)
              setSubscriber("")
            })
        }
      })
  }

  return (
    <>
      <IdComponent title="footer" />
      <ContainerWrapper>
        <Container className="py-5 position-relative">
          <FooterOrnament fluid={footerImg} alt="footer" />
          <Link to="https://itsjack.com" target="_blank">
            <FooterJackBussiness
              right={isEnglish && "-160px"}
              right768={isEnglish ? "-120px" : "-100px"}
            >
              {props.Subscribe.JackAds} <img src={jackLogo} />
            </FooterJackBussiness>
          </Link>
          <FooterContactUsTitle mbottom="100px">
            {rsr(props.Subscribe.title, "{here}", () => (
              <Link to={contactUsLink} style={{ textDecoration: "underline" }}>
                {props.Subscribe.here}
              </Link>
            ))}
          </FooterContactUsTitle>

          <Row>
            <Col md={6} style={{ position: "relative" }}>
              <LogoSocial />
              <Subscribe>
                {props.Subscribe.Subs1}
                <SubscribeBreak /> {props.Subscribe.Subs2}
              </Subscribe>
              <Form.Row
                style={{
                  width: "100%",
                  marginBottom: "2rem",
                }}
              >
                <InputGroupStyled>
                  <FormControlStyled
                    type="email"
                    placeholder={props.Subscribe.EmailPlaceholder}
                    value={subscriber}
                    onChange={onChange}
                    required
                  />
                  {emailValid ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{
                        position: "absolute",
                        right: "130px",
                        top: "10px",
                        color: "#48a9b5",
                      }}
                    />
                  ) : null}
                  <SubscribeButton onClick={onSubmit}>
                    Subscribe
                  </SubscribeButton>
                </InputGroupStyled>
              </Form.Row>
              <div className="col offset-md-2 d-flex flex-column flex-md-row align-items-center">
                <AlertDiv>
                  {done ? (
                    <small style={{ color: "#48a9b5" }}>
                      <FontAwesomeIcon icon={faCheckCircle} />
                      {props.Subscribe.Correct}
                    </small>
                  ) : null}
                  {already ? (
                    <small className="text-danger">
                      <FontAwesomeIcon icon={faTimesCircle} />
                      {props.Subscribe.Incorrect}
                      <br />
                      {props.Subscribe.Incorrect1}
                      {subscriber}
                      {props.Subscribe.Incorrect2}
                    </small>
                  ) : null}
                </AlertDiv>
              </div>

              <NavbarFooter props={props} propsLink={propsLink} />

              <Content>{props.Partnership.Title}</Content>
              <Description>
                {props.Partnership.Question}
                <br />
                <EmailSpan>hello@transfez.com</EmailSpan>
              </Description>
            </Col>
            <Col md={6} className="d-flex flex-column justify-content-between">
              <div className="d-flex flex-column align-items-end text-right">
                <img
                  src={transfezLogo}
                  alt="logo"
                  className="mb-4"
                  style={{ width: 200 }}
                />
                <Description>
                  Prudential Centre Lt 22
                  <br />
                  Kota Kasablanka
                  <br />
                  Jl Casablanca Raya Kav 88
                  <br />
                  Jakarta Selatan
                  <br />
                  Indonesia
                </Description>
              </div>

              <FooterLogo props={props} />
            </Col>
          </Row>

          {/* <Row className="mb-3">
            <Col
              md={5}
              className="d-flex justify-content-md-end align-items-center"
            >
              <a href="https://www.instagram.com/transfez.id/" target="_blank">
                <LogoSocmed src={igLogo} mr="1.5rem" alt="Logo Instagram" />
              </a>
              <a href="https://twitter.com/transfez" target="_blank">
                <LogoSocmed src={twitterLogo} mr="1.5rem" alt="Logo Twitter" />
              </a>
              <a href="https://web.facebook.com/Transfez.id/" target="_blank">
                <LogoSocmed src={fbLogo} mr="1.5rem" alt="Logo Facebook" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCetpYhmtJh4JZj_bwaVo5vg"
                target="_blank"
              >
                <LogoSocmed src={ytLogo} mr="1.5rem" alt="Logo Instagram" />
              </a>
              <a
                href="https://www.linkedin.com/company/transfez/"
                target="_blank"
              >
                <LogoSocmed
                  src={linkedInLogo}
                  mr="1.5rem"
                  alt="Logo Linked in"
                />
              </a>
              <a href="https://www.tiktok.com/@transfez" target="_blank">
                <LogoSocmed src={tiktokLogo} alt="Logo Tiktok" mr="0" />
              </a>
            </Col>
          </Row> */}
          <BottomFooter props={props} propsLink={propsLink} />
        </Container>
      </ContainerWrapper>
    </>
  )
}

export default Footer
