import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { InputGroup, FormControl, Col } from "react-bootstrap"
import { CustomButton } from "../Buttons/parent"

export const Description = styled.p`
  font-size: 14px;
  font-weight: 500; ;
`
export const Disclaimer = styled.p`
  font-size: 12px;
  font-family: "GothamBook";
  text-align: center;
`
export const Copyright = styled.p`
  font-size: 12px;
  font-family: "GothamBook";
  color: white;
  text-align: center;
`
export const Subscribe = styled.p`
  text-align: left;
  font-weight: 700;
`
export const ContentUpper = styled.h5`
  font-family: "GothamMedium";
  font-size: 1rem;
  color: white;
`
export const LogoPPATK = styled.img`
  margin-left: 0.5rem;
  width: 50px;
  height: 50px;
  alt: "logoPPATK";
`
export const LogoBI = styled.img`
  margin-right: 0.5rem;
  width: 50px;
  height: 50px;
  alt: "logoBI";
`
export const LogoAPPUI = styled.img`
  width: 50px;
  height: 50px;
  alt: "logoAPPUI";
`

export const LogoSocmed = styled.img`
  margin-right: ${props => props.mr || "1rem"};
  width: 55px;
  height: 55px;
  alt: ${props => props.alt};
  @media (max-width: 576px) {
    width: 35px;
    height: 35px;
    margin-right: ${props => props.mrRes};
  }
`

export const SubscribeButton = styled(CustomButton)`
  margin-left: -1px;
  background-color: #da649f;
  color: white;
  padding: 6px 33px;
  font-family: "Typefez-reg";
  border: none;
  border-radius: 0;
  outline: none;
  :hover,
  :focus,
  :active {
    transition: 300ms;
    color: #ffc352;
    /* filter: brightness(90%); */
    outline: none;
    box-shadow: none;
  }
  @media (max-width: 576px) {
    padding: 6px 15px;
  }
`

export const TextLegal = styled.span`
  font-weight: 900;
  font-family: "Typefez-bold";
`

export const LinkButton = styled(Link)`
  font-family: "GothamBook";
  color: #da649f;
  font-size: 12px;
  text-decoration: none;
  :hover {
    text-decoration: none;
    color: #dd7bac !important;
  }
`

export const Content = styled.h6`
  position: relative;
`

export const InputGroupStyled = styled(InputGroup)`
  border: 2px solid white;
  border-radius: 10px;
  overflow: hidden;
  font-family: "GothamBook";
  font-size: 1rem;
  width: 490px;
  :hover,
  :focus,
  :active {
    outline: none;
    box-shadow: none;
  }
`

export const FormControlStyled = styled(FormControl)`
  background-color: transparent !important;
  color: white !important;
  height: 50px;
  padding-left: 23px;
  border-radius: 1rem;
  border: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  ::placeholder {
    font-family: "Typefez-reg";
    font-weight: 600;
    font-size: 1rem;
    color: white;
  }
  :hover,
  :focus,
  :active {
    outline: none;
    box-shadow: none;
  }
  @media (max-width: 576px) {
    ::placeholder {
      font-size: 14px;
    }
  }
`

export const SubscribeCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: flex-end !important;
  text-align: left;
  @media (min-width: 768px) {
    text-align: right !important;
  }
`

export const SubscribeBreak = styled.br`
  display: none;
  @media (min-width: 768px) {
    display: inline;
  }
`

export const NavbarFooterCol = styled(Col)`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding: 0;

  a {
    font-family: "GothamMedium";
  }

  ul {
    line-height: 17.5px;
  }

  @media (min-width: 768px) {
    flex: ${props => props.flex};
    max-width: ${props => props.mw};
  }
`
export const BottomFooterLink = styled(Link)``

export const AlertDiv = styled.div`
  flex: 1 1 auto;
  > small {
    position: absolute;
    left: 60px;
    bottom: -14px;
    font-size: 9px;
    font-weight: 500;
    letter-spacing: 0.05em;
    font-family: "GothamBook";
  }
`

export const ContainerWrapper = styled.div`
  background-color: #102c57;
  position: relative;
  color: white;
  p {
    color: white;
  }

  a {
    color: #da649f;
  }
`

export const FooterOrnament = styled(Img)`
  position: absolute !important;
  width: 100%;
  top: -180px;
  left: -280px;

  @media (max-width: 576px) {
    transform: scale(0.3);
  }

  @media (min-width: 576px) {
    top: -120px;
    left: -440px;
  }

  /* @media (min-width: 1024px) {
    left: -300px;
  } */

  @media (min-width: 1280px) {
    top: -140px;
    left: -400px;
    width: 553px;
    height: 384px;
    transform: unset;
  }
`
export const FooterContactUsTitle = styled.h2`
  font-size: 22px;
  font-family: "Typefez-reg";
  font-weight: 400 !important;
  text-align: center;
  margin-bottom: ${p => p.mbottom};
  margin-top: 20px;
`

export const EmailSpan = styled.span`
  color: #da649f;
  font-weight: bold;
`

export const LegalPanel = styled.div`
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 15px;
  gap: 20px;
`

export const ImageLogo = styled.img`
  width: ${p => p.width || "50px !important"};
  height: ${p => p.height || "50px !important"};
`

export const FooterJackBussiness = styled.div`
  position: absolute;
  right: ${p => p.right || "-160px"};
  top: -40px;
  padding: 26px;
  background-color: black;
  border-radius: 15px 0px 0px 15px;
  border: 1px solid #94ef48;
  width: 500px;
  font-size: 22px;
  display: flex;
  align-items: center;
  gap: 16px;
  color: white !important;

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 18px;
    right: ${p => p.right768 || "-120px"};
  }
`
