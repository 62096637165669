import React from "react"
import { useLocation } from "@reach/router"

import { Col, Row } from "react-bootstrap"
import appuiLogo from "../../images/logo-appui.png"
import biLogo from "../../images/logo-bi-new.webp"
import ppatkLogo from "../../images/logo-ppatk.png"
import aspiLogo from "../../images/logo-aspi.png"
import isoLogo from "../../images/logo-iso.webp"

import {
  Description,
  ImageLogo,
  LegalPanel,
  LogoAPPUI,
  LogoBI,
  LogoPPATK,
  TextLegal,
} from "./FooterStyle"

function FooterLogo({ props }) {
  const { Legal } = props
  const location = useLocation()
  const lang = location.pathname.includes("/en")
  const isSG = location.pathname.includes("/sg")
  if (isSG) return null

  const datasFooter = [
    {
      text: `Bank Indonesia, PPATK & ISO`,
      logo: biLogo,
      logo2: ppatkLogo,
      logo3: isoLogo,
      isBi: true,
      textLegalEn: (
        <>
          {Legal.Legal1}
          <br />
          {Legal.Legal2}
        </>
      ),
      textLegalId: (
        <>
          {Legal.Legal1}
          <br />
        </>
      ),
    },
    {
      text: `ASPI & APPUI`,
      logo: aspiLogo,
      logo2: appuiLogo,
      isAspi: true,
      textLegalEn: (
        <>
          {Legal.Legal3}
          <br />
          {Legal.Legal4}
        </>
      ),
      textLegalId: (
        <>
          {Legal.Legal3}
          <br />
        </>
      ),
    },
  ]

  return (
    <Row className="d-flex justify-content-md-end" style={{ fontSize: "12px" }}>
      {datasFooter?.map((item, index) => {
        const {
          text,
          logo,
          logo2,
          logo3,
          textLegalEn,
          textLegalId,
          isBi,
          isAspi,
          isAppui,
        } = item || {}
        const isFirstArray = index == 0
        return (
          <div index={index} className="pl-3">
            <Col className={`col-auto ${isFirstArray ? "mb-4" : ""}`}>
              <Description>
                {lang ? textLegalEn : textLegalId}
                <br />
                <TextLegal>{text}</TextLegal>
              </Description>

              <LegalPanel>
                <ImageLogo
                  width={isAspi && "70px"}
                  height={isAspi && "auto"}
                  src={logo}
                  alt={`logo-${text}`}
                />
                {logo2 && <ImageLogo src={logo2} alt={`logo-${text}`} />}
                {logo3 && <ImageLogo src={logo3} alt={`logo-${text}`} />}
              </LegalPanel>
            </Col>
          </div>
        )
      })}
    </Row>
  )
}
export default FooterLogo
