import React from "react"
import { Col, Row } from "react-bootstrap"
import { useLocation } from "@reach/router"
import { ContentUpper, LinkButton, NavbarFooterCol } from "./FooterStyle"

const Navbars = ({ data }) => (
  <>
    {data.map((detail, id) => {
      const { title, href } = detail
      return (
        <li key={id}>
          <LinkButton to={href}>{title}</LinkButton>
        </li>
      )
    })}
  </>
)
const NavbarFooter = ({ props, propsLink }) => {
  const {
    HowItWorks,
    Countries,
    AboutUs,
    Affiliate,
    Home,
    NewsBlog,
    ContactUs,
    Faq,
    SwiftCodes,
    Download,
  } = propsLink.Label
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")

  // SG CONTENT FOOTER //

  const isSg = location.pathname.includes("/sg")
  const faqLink = isSg ? (isEnglish ? `/en/sg/faq` : `/sg/faq`) : propsLink.Faq
  const isIdReferral =
    location.pathname === "/referral" || location.pathname === "/en/referral"
  const isSgReferral =
    location.pathname === "/sg/referral" ||
    location.pathname == "/en/sg/referral"
  const referralTncText = isEnglish
    ? `Terms and Conditions`
    : `Mekanisme Program`
  const referralText = isEnglish ? "Referral" : "Referral"
  const referralTncLinkId = isEnglish
    ? `/en/referral/term-and-condition-referral-program`
    : `/referral/term-and-condition-referral-program`
  const referralTncLinkSg = isEnglish
    ? `/en/sg/referral/term-and-condition-referral-program`
    : `/sg/referral/term-and-condition-referral-program`
  const referralLinkId = isEnglish ? "/en/referral" : "/referral"
  const referralLinkSg = isEnglish ? "/en/sg/referral" : "/sg/referral"
  const navbarReferralSg = isSg
    ? isSgReferral
      ? { title: referralTncText, href: referralTncLinkSg }
      : { title: referralText, href: referralLinkSg }
    : {}
  const navbarReferralId = !isSg
    ? isIdReferral
      ? { title: referralTncText, href: referralTncLinkId }
      : { title: referralText, href: referralLinkId }
    : {}

  // ----------------------- //

  const navbarItems = [
    [
      { title: AboutUs, href: propsLink.AboutUs },
      { title: Affiliate, href: propsLink.Affiliate },
      { title: SwiftCodes, href: propsLink.SwiftCodes },
      { title: Countries, href: propsLink.Countries },
    ],
    [
      { title: Home, href: propsLink.MainPage },
      { title: NewsBlog, href: propsLink.NewsBlog },
      { title: Download, href: propsLink.Download },
      navbarReferralSg,
      navbarReferralId,
    ],
    [
      { title: ContactUs, href: propsLink.ContactUs },
      { title: Faq, href: faqLink },
    ],
  ]
  return (
    <div className="mb-5">
      <NavbarFooterCol md={4} className="mb-4">
        <ContentUpper>Transfez</ContentUpper>
        <ul className="list-unstyled w-100">
          <Row>
            <Col sm={4}>
              <Navbars data={navbarItems[0]} />
            </Col>
            <Col sm={8}>
              <Navbars data={navbarItems[1]} />
            </Col>
          </Row>
        </ul>
      </NavbarFooterCol>
      <NavbarFooterCol>
        <ContentUpper>{props.FooterLi.Title}</ContentUpper>
        <ul className="list-unstyled">
          <Navbars data={navbarItems[2]} />
        </ul>
      </NavbarFooterCol>
    </div>
  )
}
export default NavbarFooter
