import React from "react"

export const IdComponent = ({ title }) => {
  return (
    <a
      id={title}
      style={{ display: "block", paddingTop: "80px", marginTop: "-80px" }}
    ></a>
  )
}
