import React from "react";
import LogoTiktok from "../../images/logo-tiktok.svg";
import LogoYoutube from "../../images/logo-youtube.svg";
import LogoTwitter from "../../images/logo-twitter.svg";
import LogoFacebook from "../../images/logo-facebook.svg";
import LogoInstagram from "../../images/logo-instagram.svg";
import styled from "styled-components";

export const LogoSocial = () => {
  return (
    <div
      className="d-flex mb-2"
      style={{ gap: 6, zIndex: 30, position: "relative" }}
    >
      <HrefLogo
        src={LogoTiktok}
        alt="LogoTiktok"
        href="https://www.tiktok.com/@transfez"
      />
      <HrefLogo
        src={LogoYoutube}
        alt="LogoYoutube"
        href="https://www.youtube.com/c/Transfez"
      />
      <HrefLogo
        src={LogoTwitter}
        alt="LogoTwitter"
        href="https://twitter.com/transfez"
      />
      <HrefLogo
        src={LogoFacebook}
        alt="LogoFacebook"
        href="https://www.facebook.com/Transfez.id/"
      />
      <HrefLogo
        src={LogoInstagram}
        alt="LogoInstagram"
        href="https://www.instagram.com/transfez.id/"
      />
    </div>
  );
};

const HrefLogo = ({ href, src, alt }) => {
  return (
    <a href={href} target="_blank">
      <LogoStyled src={src} alt={alt} />
    </a>
  );
};

const LogoStyled = styled.img`
  width: 40px;
`;